@import "./../../../styles/module-global/colors.module.scss";
@import "./../../../styles/module-global/mixin.module.scss";

.mobileFilter {
	display: none;
}

@media (max-width: 576px) {
	.mobileFilter {
		display: block;
		position: fixed;
		width: 100%;
		height: calc(100% - 58px);
		top: 58px;
		left: 0;
		z-index: 152;
		transform: translateX(0);
		transition: transform ease 0.3s;

		&.hidden {
			transform: translateX(calc(-100%));
			transition: transform ease 0.3s;
		}
	}

	.filterPanel {
		background-color: #ffffff;
		//width: calc(100% - 50px);
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 159;
		overflow-y: scroll;

		.innerWrapper {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			min-height: 100vh;
		}

		.filterMobileContent {
			.headerWrapper {
				display: flex;
				align-items: center;
				margin: 0;
				margin-bottom: 10px;

				span {
					height: 1px;
				}

				& > p {
					margin: 0 21px;
					font-weight: 600;
					font-size: 16px;
					line-height: 18.75px;
					color: #363636;
				}

				& > div {
					margin: 0 10px 0 0;
					@include text(16px, 500, 19px, $gray);
				}
			}

			h3 {
				font-size: 1rem;
				text-transform: uppercase;
				margin-left: 1.25rem;
				margin-right: 1.25rem;
			}

			h4 {
				font-weight: 500;
				margin-right: 0.25rem;
			}

			.badgesWrapper {
				display: flex;
				flex-wrap: wrap;
				padding-left: 25px;
				padding-right: 25px;

				.filterBadge {
					margin-right: 10px;
					margin-bottom: 10px;
					padding: 8px;
					font-size: 12px;
					border-radius: 0;
				}
			}
		}
	}

	.togglePanelButton {
		width: 50px;
		height: 4rem;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #cacaca;
		font-size: 14px;
		background-color: $white;
		position: absolute;
		top: 55%;
		right: 0;
		transform: translateY(-50%);
		border-radius: 0px 5px 5px 0px;
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
		cursor: pointer;

		& .searchIcon path {
			fill: #fcb53b;
		}
	}

	.headerWrapperToggler {
		width: 30px;
		height: 30px !important;
		cursor: pointer;
		position: relative;
		transition: all 0.5s;
		margin-left: 0.5rem;
		background-color: #f8f0e2;

		&::before {
			position: absolute;
			content: "";
			width: 1rem;
			height: 3px;
			background-color: #fcb53b;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: transform 0.3s;
		}
		&::after {
			position: absolute;
			content: "";
			width: 3px;
			height: 1rem;
			background-color: #fcb53b;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: transform 0.3s;
		}

		&.isHidden {
			transform: rotate(90deg);
		}

		&.isHidden::after {
		}
		&.isHidden::before {
			visibility: hidden;
		}
	}

	.title {
		display: flex;
		flex-direction: column;
		height: 0px;
		overflow: hidden;
		transition: all 0.5s;

		&.active {
			height: max-content;
			overflow: visible;
			transition: all 0.5s;
		}
	}

	.categoriestTitle {
		font-weight: 400;
		padding: 0.5rem;
	}

	//NewCategories

	.titleNew {
		height: auto;
		max-height: 0;
		background-color: #fcb53b;

		&.active {
			max-height: 200px;
		}
	}

	.submenu {
		display: block;
		position: fixed;
		left: 0;
		bottom: 0;
		height: 0;
		background-color: white;
		width: calc(100% - 60px);
		// transform: translateY(0);
		// transition: transform ease 0.3s;
		//z-index: 999;

		&.active {
			height: 50%;
			overflow: auto;
			padding-bottom: 70px;
			margin-top: 10px;
			// padding-left: 1rem;
			// transform: translateY(calc(-50%));
		}
	}

	.filterMobilButtom {
		font-weight: 400;
		border-radius: 0;
		position: fixed;
		z-index: 200;
		bottom: 0;
		width: calc(100% - 60px);
		height: 60px;
		box-shadow: 8px 0px 4px rgba(0, 0, 0, 0.1);
		background-color: #ffffff;
	}

	.submenuTitle {
		font-weight: 400;
		border-radius: 0;
		position: fixed;
		z-index: 200;
		width: calc(100% - 60px);
		height: 60px;
		background-color: #ffffff;
		box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.1);
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 500;
	}

	.categoriesBox {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
	}

	.submenuBox {
		padding: 1rem;
		padding-top: 65px;
	}

	.submenuItem {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		border-bottom: 1px dashed #000000;
	}

	.submenuChildrenBox {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		padding: 5rem 1rem 2rem 1rem;
	}

	.submenuConteiner {
		display: flex;
		flex-direction: column;
	}

	.categoriesNewSumbenu {
		font-weight: 400;
		border-radius: 0;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 201;
		bottom: 0;
		width: calc(100% - 60px);
		height: 100%;
		overflow: scroll;
		box-shadow: 8px 0px 4px rgba(0, 0, 0, 0.1);
		background-color: #ffffff;
	}

	.categoriesNewSumbenuButton {
		font-weight: 400;
		border-radius: 0;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 200;
		width: calc(100% - 60px);
		height: 40px;
		box-shadow: 8px 0px 4px rgba(0, 0, 0, 0.1);
		background-color: rgb(151, 147, 147);

		display: flex;
		align-items: center;
		justify-content: center;
	}

	.categoriesNewSumbenuChildrenBox {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		padding: 1rem;
	}

	.categoriesNewSumbenuHeader {
		font-weight: 400;
		border-radius: 0;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 200;
		width: calc(100% - 60px);
		height: 40px;
		box-shadow: 8px 0px 4px rgba(0, 0, 0, 0.1);
		background-color: #363636;

		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
	}

	.categoriesNewSumbenuconteiner {
		padding: 5rem 1rem 4rem 1rem;
	}

	.categoriesNewSumbenutitleBox {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.deliveryTypes {
		width: 90%;
		padding: 1rem;
	}
	.deliverySeolect {
		border: 1px solid rgba(0, 0, 0, 0.15);
	}

	.badgesNoWrapper {
		display: flex;
		flex-wrap: wrap;
		padding-left: 25px;
		padding-right: 25px;
	}

	.showResultsBtnBox {
		width: 100%;
		height: 60px;

		position: fixed;
		bottom: 0;

		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #ffffff;

		padding: 10px;

		border-top: 2px solid ffffff;
	}

	.showResultsBtn {
		font-weight: 400;
		border-radius: 0;
		z-index: 200;
		bottom: 0;
		width: 100%;
		max-width: 370px;
		color: #ffffff;
	}

	.hiddenConten {
		display: none;
	}

	.boxItem {
		border: 1px solid red;
	}
}

.seo {
	position: absolute;
	border: 1px solid red;
	top: 0;
	left: 0;
	backdrop-filter: blur(1px);
	background: rgba(253, 253, 253, 0.74);
	// opacity: .5;
	z-index: 100;
	width: 100%;
	height: 6rem;
}
